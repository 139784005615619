import { BigNumber, Contract, ethers } from "ethers";
import { toast } from "react-toastify";

import { getAirdropData } from "./moralis";
import AttributeMutationPoolFacet from "./abis/AttributeMutationPoolFacet.json";
import ERC1155 from "./abis/ERC1155.json";
import TokenSaleFacet from "./abis/AirdropTokenSaleFacet.json";
import MerkleAirdropFacet from "./abis/MerkleAirdropFacet.json";

const DIAMOND_ADDRESS = process.env["REACT_APP_DIAMOND_ADDRESS"] || "";
const ERC1155_ADDRESS = process.env["REACT_APP_ERC1155_ADDRESS"] || "";

let provider: ethers.providers.Web3Provider;
let signer: ethers.providers.JsonRpcSigner;

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
}

// get a reference to the contracts
export const getDiamondFacet = async (abi: string): Promise<Contract> => {
  return new ethers.Contract(DIAMOND_ADDRESS, abi, signer);
};

export const getContractDeployment = async (
  abi: string,
  address: string
): Promise<Contract> => {
  return new ethers.Contract(address, abi, signer);
};

export const stake = async (tokenId: string, walletAddress: string) => {
  const tokenContract = await getContractDeployment(
    JSON.stringify(ERC1155),
    ERC1155_ADDRESS
  );
  const attributeMutationPoolContract = await getDiamondFacet(
    JSON.stringify(AttributeMutationPoolFacet)
  );
  await stakeAndWait(
    walletAddress,
    ethers.BigNumber.from(tokenId),
    attributeMutationPoolContract,
    tokenContract
  );
};

const stakeAndWait = async (
  walletAddress: string,
  token: BigNumber,
  attributeMutationPoolContract: Contract,
  tokenContract: Contract
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    attributeMutationPoolContract.on(
      "TokenDeposited",
      (staker: string, tokenId: BigNumber) => {
        if (staker.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
          console.log(`TokenDeposited: ${staker} ${tokenId}`);
          toast(`Token Staked!!`);
          resolve();
        }
      }
    );

    tokenContract.on(
      "ApprovalForAll",
      async (account: string, operator: string, approved: boolean) => {
        if (account.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
          console.log(`ApprovalForAll: ${account} ${operator} ${approved}`);
          toast(`Token Approved!!`);
          const tx = await attributeMutationPoolContract.stake(token);
          await tx.wait();
        }
      }
    );

    try {
      const isApproved = await tokenContract.isApprovedForAll(
        walletAddress,
        attributeMutationPoolContract.address
      );
      if (isApproved) {
        const txStake = await attributeMutationPoolContract.stake(token);
        await txStake.wait();
      } else {
        const txSetApproval = await tokenContract.setApprovalForAll(
          attributeMutationPoolContract.address,
          true
        );
        await txSetApproval.wait();
      }
    } catch (error) {
      console.error(error);
      handleErrors(error);
    }
  });
};

export const unstake = async (tokenId: string, walletAddress: string) => {
  const attributeMutationPoolContract = await getDiamondFacet(
    JSON.stringify(AttributeMutationPoolFacet)
  );
  attributeMutationPoolContract.on(
    "TokenWithdrawn",
    (staker: string, tokenId: BigNumber, totalAccrued: BigNumber) => {
      if (staker.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
        console.log(`TokenWithdrawn: ${staker} ${tokenId} ${totalAccrued}`);
        toast("Token unstaked!!");
      }
    }
  );

  try {
    const tx = await attributeMutationPoolContract.unstake(tokenId);
    await tx.wait();
    console.log("token unstaked");
  } catch (error) {
    console.error(error);
    handleErrors(error);
  }
};

export const getAccuredPower = async (tokenId: string) => {
  const attributeMutationPoolContract = await getDiamondFacet(
    JSON.stringify(AttributeMutationPoolFacet)
  );
  try {
    const accuredPower = await attributeMutationPoolContract.getAccruedValue(
      tokenId
    );
    return accuredPower;
  } catch (error) {
    console.error(error);
  }
};

export const redeemToken = async (
  accountAddress: string,
  quantity: number,
  txval: string
) => {
  const tokenSale = await getDiamondFacet(JSON.stringify(TokenSaleFacet));

  const merkleAirdropFacet = await getDiamondFacet(
    JSON.stringify(MerkleAirdropFacet)
  );

  const tokenSaleId = process.env["REACT_APP_TOKENSALE_ADDRESS"] || "";
  const airdropId = process.env["REACT_APP_AIRDROP_ADDRESS"] || "";
  const airdropEndTime = process.env["REACT_APP_AIRDROP_END_TIME"] || "";
  const currentTime = new Date().getTime();

  console.log(accountAddress, quantity, txval, airdropId, tokenSaleId);

  let airdropData: any;

  console.log(parseInt(airdropEndTime), currentTime);

  if (parseInt(airdropEndTime) > currentTime) {
    airdropData = await getAirdropData(airdropId, accountAddress, quantity);
  } else {
    airdropData = {};
  }

  console.log(airdropData);

  const purchaseAndWait = async (
    tokenSaleContract: Contract,
    quantity: number,
    address: string
  ) => {
    return new Promise(async (resolve: any, reject): Promise<void> => {
      tokenSale.on(
        "TokenPurchased",
        async (
          tokenSaleId: any,
          purchaser: any,
          tokenId: any,
          quantity: any
        ) => {
          if (
            purchaser.toLocaleLowerCase() === accountAddress.toLocaleLowerCase()
          ) {
            console.log(
              `TokenPurchased: ${tokenSaleId} ${purchaser} ${tokenId} ${quantity}`
            );
            toast("Token Purchased!!");
            resolve();
          }
        }
      );

      const txValue = BigNumber.from(
        ethers.utils.parseEther(txval + "")
      ).toHexString();

      try {
        console.log(`redeeming ${quantity} of ${txValue}`);

        const tx = await tokenSaleContract.redeemToken(
          tokenSaleId,
          0,
          0,
          address,
          quantity,
          txValue,
          [],
          {
            value: txValue,
          }
        );
        await tx.wait();
        console.log("redeemed");
      } catch (error: any) {
        handleErrors(error);
      }
    });
  };

  const redeemAndWait = async (
    tokenSaleContract: Contract,
    airdropData: any,
    accountAddress: string
  ) => {
    return new Promise(async (resolve: any, reject): Promise<void> => {
      const { airdropId, key, quantity, value, leafHash, proof } = airdropData;

      merkleAirdropFacet.on(
        "AirdropRedeemed",
        async (
          airdropId: any,
          beneficiary: any,
          tokenHash: any,
          proof: any,
          amount: any
        ) => {
          if (
            beneficiary.toLocaleLowerCase() ===
            accountAddress.toLocaleLowerCase()
          ) {
            console.log(
              `AirdropRedeemed: ${airdropId} ${beneficiary} ${tokenHash} ${proof} ${amount} ${value}`
            );
            toast("Airdrop Redeemed!!");
            resolve();
          }
        }
      );

      const txValue = BigNumber.from(
        ethers.utils.parseEther(txval + "")
      ).toHexString();

      try {
        console.log(`redeeming ${quantity} of ${txValue}`);

        const tx = await tokenSaleContract.redeemToken(
          tokenSaleId,
          airdropId,
          leafHash,
          key,
          quantity,
          txValue,
          proof,
          {
            value: txValue,
          }
        );
        await tx.wait();
        console.log("redeemed");
      } catch (error) {
        handleErrors(error);
      }
    });
  };

  try {
    if (airdropData && Object.keys(airdropData).length) {
      await redeemAndWait(tokenSale, airdropData, accountAddress);
    } else {
      await purchaseAndWait(tokenSale, quantity, accountAddress);
    }
    window.location.href = "/#/pub";
  } catch (error) {
    console.error(error);
  }
};

export const handleErrors = (error: any) => {
  if (error?.error) {
    if (error.error?.code === -32000) {
      toast.error("Insufficient funds for gas");
    } else {
      toast.error(error.error?.message);
    }
  }
};
